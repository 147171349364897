export default class HomeVideo {
  constructor() {
    this.initOpenVideoListener();
  }

  initOpenVideoListener() {
    document.getElementById('open-homepage-video')?.addEventListener('click', this.openVideo);
  }

  openVideo() {
    const homeVideo = document.getElementById('home-video');
    let htmlVideo = `<iframe
                      id="home-video"
                      class="embed-responsive-item"
                      src="${homeVideo.dataset.videoUrl}"
                      width="640"
                      height="360"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen />`;

    homeVideo.innerHTML = htmlVideo;

    $('#modal-view').modal('show');

    $('#modal-view').on('hidden.bs.modal', function () {
      homeVideo.innerHTML = '';
    });
  }
}
