export default class StudentDispositions {
  constructor() {
    this.initializeInputSearch();
  }

  initializeInputSearch() {
    let inputSearch = document.querySelector('.search-student input[type="search"]');
    let currentSearchParams = new URLSearchParams(window.location.search);
    let typingTimer;
    let doneTypingInterval = 750;

    inputSearch.addEventListener('keyup', (evt) => {
      clearTimeout(typingTimer);

      if (evt.target.value) {
        typingTimer = setTimeout(() => {
          currentSearchParams.set('q', evt.target.value);
          window.location.search = currentSearchParams.toString();
        }, doneTypingInterval);
      } else {
        currentSearchParams.delete('q');
        window.location.search = currentSearchParams.toString();
      }
    });

    inputSearch.addEventListener('change', (evt) => {
      if (!evt.target.value) {
        currentSearchParams.delete('q');
        window.location.search = currentSearchParams.toString();
      }
    });

    inputSearch.addEventListener('search', (evt) => {
      if (!evt.target.value) {
        currentSearchParams.delete('q');
        window.location.search = currentSearchParams.toString();
      }
    });
  }
}
