const debounce = (func, delay) => {
  let timeoutId;

  return function () {
    const context = this;
    const args = arguments;

    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
};

const recalculate = () => {
  document.querySelectorAll('[data-js-readmore]').forEach((el) => {
    el.classList.remove('wtc-lineclamp');
    el.classList.add('wtc-lineclamp');

    const clamped = el.scrollHeight > el.clientHeight;
    el.classList[clamped ? 'add' : 'remove']('wtc-lineclamp');

    if (clamped && !el.insertedReadMore) {
      const readmore = document.createElement('a');
      readmore.textContent = 'Read more';
      readmore.href = '/';
      el.insertedReadMore = true;
      el.insertAdjacentElement('afterend', readmore);
      readmore.addEventListener('click', (btnev) => {
        btnev.preventDefault();
        el.classList.remove('wtc-lineclamp');
        el.removeAttribute('data-js-readmore');
        btnev.target.remove();
      });
    }
  });
};

document.addEventListener('turbolinks:load', recalculate);
window.addEventListener('resize', debounce(recalculate, 250));
