import moment from 'moment';
import * as daterangepicker from 'daterangepicker'; // eslint-disable-line no-unused-vars
import Turbolinks from 'turbolinks';
window.Tablesort = require('tablesort');
require('tablesort/src/sorts/tablesort.number');

export default class Analytics {
  constructor() {
    this.initializeDateRangePicker();
    this.initializeTablesort();
    this.initializeListMore();
    this.fixProvidersTablePagination();
  }

  initializeDateRangePicker() {
    const $datesRangePicker = $('.dates-range-picker');

    $datesRangePicker.daterangepicker(
      {
        startDate: moment($datesRangePicker[0].dataset.startDate),
        endDate: moment($datesRangePicker[0].dataset.endDate),
        opens: 'left',
        alwaysShowCalendars: true,
        ranges: {
          Today: [moment(), moment()],
          'Last Week': [moment().subtract(7, 'day'), moment()],
          'Last 14 Days': [moment().subtract(14, 'day'), moment()],
          'Last 30 Days': [moment().subtract(30, 'day'), moment()],
          'Last 3 Months': [moment().subtract(3, 'month'), moment()],
          'Last 6 Months': [moment().subtract(6, 'month'), moment()],
          'Last Year': [moment().subtract(1, 'year'), moment()]
        }
      },
      (start, end) => {
        Turbolinks.visit(`/analytics?sd=${start.format('YYYY-MM-DD')}&ed=${end.format('YYYY-MM-DD')}`);
      }
    );

    $datesRangePicker.on('cancel.daterangepicker', () => {
      Turbolinks.visit('/analytics');
    });
  }

  initializeTablesort() {
    const $table = document.querySelector('.community-providers .table');

    new window.Tablesort($table);
  }

  initializeListMore() {
    let listMoreLinks = document.querySelectorAll('.list-more');

    for (let listMoreLink of listMoreLinks) {
      listMoreLink.addEventListener('click', (e) => {
        e.preventDefault();
        let listContainer = document.querySelector(e.target.dataset.target);

        listContainer.style.height = 'auto';
        listMoreLink.parentNode.removeChild(listMoreLink);
      });
    }
  }

  fixProvidersTablePagination() {
    document.querySelectorAll('[data-js-community-providers-table]').forEach((el) => {
      el.setAttribute('id', 'community-providers-table');
      if (window.location.hash == '#community-providers-table')
        setTimeout(() => {
          el.scrollIntoView();
        }, 222);

      el.querySelectorAll('[data-js-pagination] a').forEach((pagLink) => {
        if (!pagLink.href) return;
        pagLink.href = `${pagLink.href}#community-providers-table`;
      });
    });
  }
}
