import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import mapboxgl from '!mapbox-gl';

export default class OfficeMapLocator {
  static get INITIAL_ZOOM() { return 2; }
  static get DEFAULT_ZOOM() { return 14; }
  static get DEFAULT_COUNTRY() { return document.body.dataset.pageCountry; }

  constructor() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidGhlc2hyaW5rc3BhY2UiLCJhIjoiY2pveXVlcXljMmpmNDN4bWxxdXU2ZTF5bSJ9.1fSUfOi71bu24kUsjnd6Pg';

    this.mapsCollection = {};
    this.markersCollection = {};

    this.initializeMaps();
    this.initializeZipsObservers();
    this.initializeCitiesObservers();
    this.handleAddNewOfficeBtn();
  }

  initializeZipsObservers() {
    let zipInputs = document.querySelectorAll('.zip-field');

    for (let zipInput of zipInputs) {
      this.initZipObserver(zipInput);
    }
  }

  initZipObserver(zipInput) {
    let mapboxClient = mbxGeocoding({ accessToken: mapboxgl.accessToken});
    let self = this;

    zipInput.addEventListener('change', function() {
      let map = self.mapsCollection[this.dataset.target];
      let marker = self.markersCollection[this.dataset.target];
      let address = self.getFullAddress(this.dataset.target, this.value);
      let mapContainer = document.getElementById(this.dataset.target);
      let latitudeInput = mapContainer.querySelector('.office-latitude');
      let longitudeInput = mapContainer.querySelector('.office-longitude');

      mapboxClient.forwardGeocode({
        query: address,
        countries: [OfficeMapLocator.DEFAULT_COUNTRY]
      })
        .send()
        .then(response => {
        if (response.body.features[0]) {
          let coords = response.body.features[0].center;

          map.setCenter(coords);
          marker.setLngLat(coords);

          longitudeInput.value = coords[0];
          latitudeInput.value = coords[1];

          map.setZoom(OfficeMapLocator.DEFAULT_ZOOM);
        }
      });
    });
  }

  initializeCitiesObservers() {
    if (OfficeMapLocator.DEFAULT_COUNTRY != 'HK') { return; }

    let cityInputs = document.querySelectorAll('.city-field');

    for (let cityInput of cityInputs) {
      this.initializeCityObserver(cityInput);
    }
  }

  initializeCityObserver(cityInput) {
    let mapboxClient = mbxGeocoding({ accessToken: mapboxgl.accessToken});
    let self = this;

    cityInput.addEventListener('change', function() {
      let map = self.mapsCollection[this.dataset.target];
      let marker = self.markersCollection[this.dataset.target];
      let address = self.getFullAddress(this.dataset.target, this.value);
      let mapContainer = document.getElementById(this.dataset.target);
      let latitudeInput = mapContainer.querySelector('.office-latitude');
      let longitudeInput = mapContainer.querySelector('.office-longitude');

      mapboxClient.forwardGeocode({
        query: address,
        countries: [OfficeMapLocator.DEFAULT_COUNTRY]
      })
        .send()
        .then(response => {
        if (response.body.features[0]) {
          let coords = response.body.features[0].center;

          map.setCenter(coords);
          marker.setLngLat(coords);

          longitudeInput.value = coords[0];
          latitudeInput.value = coords[1];

          map.setZoom(OfficeMapLocator.DEFAULT_ZOOM);
        }
      });
    });
  }

  initializeMaps() {
    let mapContainers = document.querySelectorAll('.office-map-location');

    for (let mapContainer of mapContainers) {
      this.initMap(mapContainer);
    }
  }

  initMap(mapContainer) {
    let latitudeInput = mapContainer.querySelector('.office-latitude');
    let longitudeInput = mapContainer.querySelector('.office-longitude');

    let latitude = parseFloat(latitudeInput.value) || 40.41592697351251;
    let longitude = parseFloat(longitudeInput.value) || -99.83080752881176;

    let zoom = (latitudeInput.value && longitudeInput.value) ? OfficeMapLocator.DEFAULT_ZOOM : OfficeMapLocator.INITIAL_ZOOM;

    let map = new mapboxgl.Map({
      container: mapContainer.id,
      center: [longitude, latitude],
      zoom: zoom,
      style: 'mapbox://styles/mapbox/streets-v9'
    });

    let navControl = new mapboxgl.NavigationControl({ showCompass: false });
    map.addControl(navControl, 'bottom-right');

    let marker = new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map);

    this.mapsCollection[mapContainer.id] = map;
    this.markersCollection[mapContainer.id] = marker;

    map.on('drag', function() {
      marker.setLngLat(this.getCenter());
    });

    map.on('dragend', function() {
      let coords = this.getCenter();
      latitudeInput.value = coords.lat;
      longitudeInput.value = coords.lng;
    });
  }

  handleAddNewOfficeBtn() {
    let self = this;

    $('.profile-form-wrapper').on('cocoon:after-insert', function(e, officeAdded) {
      let zipInput = officeAdded.find('.zip-field')[0];
      let mapContainer = officeAdded.find('.office-map-location')[0];
      mapContainer.id = Date.now();
      zipInput.dataset.target = mapContainer.id;

      self.initMap(mapContainer);
      self.initZipObserver(zipInput);
    });
  }

  getFullAddress(containerID, zipCode) {
    let mapContainer = document.getElementById(containerID);
    let officeFields = mapContainer.parentNode.parentNode;
    let addressField = officeFields.querySelector('.address-field');
    let stateField = officeFields.querySelector('.state-field');
    let cityField = officeFields.querySelector('.city-field');

    if (stateField === null) {
      return `${addressField.value}, ${cityField.value}, ${zipCode}, ${OfficeMapLocator.DEFAULT_COUNTRY}`;
    } else {
      return `${addressField.value}, ${stateField.value}, ${cityField.value}, ${zipCode}, ${OfficeMapLocator.DEFAULT_COUNTRY}`;
    }
  }
}
