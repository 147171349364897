import Alpine from 'alpinejs';
import * as chosen from 'chosen-js'; // eslint-disable-line no-unused-vars
import 'jquery-timepicker/jquery.timepicker'; // eslint-disable-line no-unused-vars
import maskInput from 'vanilla-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AvatarUploader from './avatar_uploader';
import PopoversHandler from './popovers_handler';

export default class TherapistForm {
  static get DEFAULT_COUNTRY() {
    return document.body.dataset.pageCountry;
  }

  constructor() {
    this.initializeMoneyFields();
    this.initializeShowMoreButtons();
    this.initializeShowLessButtons();
    this.initializeChosenSelects();
    this.initializeAcceptingNewClients();
    this.initializeTimepickers();
    this.initializeUnsavedAvailabilityRemover();
    this.initializeOtherFields();
    this.handleAddAvailabilityBtn();
    this.initializeCharCounter();

    new AvatarUploader();
  }

  initializeShowMoreButtons() {
    let btns = document.querySelectorAll('.show-more');

    for (let btn of btns) {
      btn.addEventListener('click', function () {
        let targetElement = document.querySelector(this.dataset.target);
        let showLessButton = document.querySelector(`.show-less[data-target='${this.dataset.target}']`);
        targetElement.classList.toggle('collapsed');
        this.classList.toggle('d-none');
        showLessButton.classList.toggle('d-none');
      });
    }
  }

  initializeShowLessButtons() {
    let btns = document.querySelectorAll('.show-less');

    for (let btn of btns) {
      btn.addEventListener('click', function () {
        let targetElement = document.querySelector(this.dataset.target);
        let showMoreButton = document.querySelector(`.show-more[data-target='${this.dataset.target}']`);
        targetElement.classList.toggle('collapsed');
        this.classList.toggle('d-none');
        showMoreButton.classList.toggle('d-none');
      });
    }
  }

  initializeChosenSelects() {
    let chosenSelects = document.querySelectorAll('.chosen-select');

    for (let chosenSelect of chosenSelects) {
      let maxSelected = parseInt(chosenSelect.dataset.max);

      if (maxSelected && maxSelected > 0) {
        $(chosenSelect).chosen({ max_selected_options: maxSelected });
      } else {
        $(chosenSelect).chosen();
      }
    }

    let chosenSearchInputs = $('.chosen-search-input');
    chosenSearchInputs.attr('aria-label', 'Search');
  }

  initializeAcceptingNewClients() {
    const acceptedClientTypesGroup = document.querySelector('.accepted-client-types');
    const availabilityDatesGroup = document.querySelector('.availability-dates');
    const waitingList = document.querySelector('.waiting-list');

    if (acceptedClientTypesGroup === null) {
      return;
    }

    let acceptingRadioButton = document.querySelector('.accepting-new-clients .im-accepting');
    let notAcceptingRadioButton = document.querySelector('.accepting-new-clients .not-accepting');

    acceptingRadioButton.addEventListener('change', function () {
      acceptedClientTypesGroup.classList.remove('d-none');
      availabilityDatesGroup?.classList.remove('d-none');
      waitingList.classList.add('d-none');
    });

    notAcceptingRadioButton.addEventListener('change', function () {
      acceptedClientTypesGroup.classList.add('d-none');
      availabilityDatesGroup?.classList.add('d-none');
      waitingList.classList.remove('d-none');
    });
  }

  initializeMoneyFields() {
    let currencySymbol = TherapistForm.DEFAULT_COUNTRY == 'GB' ? '£ ' : '$ ';

    const moneyMask = createNumberMask({
      prefix: currencySymbol,
      suffix: '',
      allowDecimal: true
    });

    let moneyInputs = document.querySelectorAll('.input-money');

    for (let input of moneyInputs) {
      maskInput({
        inputElement: input,
        mask: moneyMask
      });
    }
  }

  handleAddAvailabilityBtn() {
    if (
      window.location.pathname.match(/^\/profile\/payment/) ||
      window.location.pathname.match(/^\/group-profile\/payment/)
    ) {
      let self = this;

      $('.profile-form-wrapper').on('cocoon:after-insert', function () {
        self.initializeTimepickers();
        new PopoversHandler();
      });
    }
  }

  initializeUnsavedAvailabilityRemover() {
    let formWrapper = document.querySelector('.profile-form-wrapper');

    formWrapper.addEventListener('click', function (evt) {
      let removeAvailabilityLink = evt.target.closest('a.remove-availability-date');

      if (removeAvailabilityLink) {
        evt.preventDefault();

        let availabilityDateFields = removeAvailabilityLink.parentNode;
        let availabilityDateFieldsParent = availabilityDateFields.parentNode;

        availabilityDateFieldsParent.removeChild(availabilityDateFields);
      }
    });
  }

  initializeTimepickers() {
    $('.input-time').timepicker({
      timeFormat: 'hh:mm p',
      interval: 15,
      minTime: '0:00am',
      maxTime: '11:59pm',
      dynamic: false,
      dropdown: true,
      change: function (time) {
        if (this.hasClass('end-time')) {
          return;
        }

        let endTimeInput = this.parent().next().find('.end-time');

        if (endTimeInput.val() === '') {
          time.setHours(time.getHours() + 1);
          endTimeInput.timepicker().setTime(time);
        }
      }
    });
  }

  initializeOtherFields() {
    let otherInputs = document.querySelectorAll('.input-show-other');

    for (let otherInput of otherInputs) {
      if (otherInput.type == 'radio') {
        let groupName = otherInput.name;
        let radiosFromGroup = document.querySelectorAll(`input[name='${groupName}']`);

        for (let radio of radiosFromGroup) {
          radio.addEventListener('change', function () {
            if (this.value == 'Other') {
              otherInput.parentNode.nextElementSibling.querySelector('.input-other').value = '';
              otherInput.parentNode.nextElementSibling.classList.remove('d-none');
            } else {
              otherInput.parentNode.nextElementSibling.querySelector('.input-other').value = '';
              otherInput.parentNode.nextElementSibling.classList.add('d-none');
            }
          });
        }
      } else {
        otherInput.addEventListener('change', function () {
          if (this.checked) {
            this.parentNode.nextElementSibling.querySelector('.input-other').value = '';
            this.parentNode.nextElementSibling.classList.remove('d-none');
          } else {
            this.parentNode.nextElementSibling.querySelector('.input-other').value = '';
            this.parentNode.nextElementSibling.classList.add('d-none');
          }
        });
      }
    }
  }

  initializeCharCounter() {
    document.querySelectorAll('[data-js-charcounter]').forEach((el) => {
      const softMaxLimit = 250;
      const feedback = el.querySelector('[data-js-charcounter-feedback]');
      const input = el.querySelector('[data-js-charcounter-input]');

      el.setAttribute('x-data', 'tsscounter');
      input.setAttribute('x-model', 'text');
      feedback.setAttribute('x-text', 'feedback');

      Alpine.data('tsscounter', () => ({
        text: input.value,
        feedback() {
          const length = this.text.length;
          if (!length) return;

          return `This text will be truncated in search results if above ${softMaxLimit} characters. Currently: ${length}/${softMaxLimit}`;
        }
      }));

      Alpine.start();
    });
  }
}
